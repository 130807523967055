<div class="container" *ngIf="screen != '' && screen == 'first'">
    <div class="row mt-2 m-sticky">
        <div class="col-md-12">
            <h3 class="lightText font-weight-bold m-heading">Report</h3>
        </div>
    </div>
    <div class="row">
        <!-- <div class="col-md-5 position-relative img-container">
            <img class="w-100 mb-3" src="../../../../assets/svg/FullReport/report-dark.svg"/>
            <div class="vertical-center text-center w-100">
                <button (click)="openSampleReport()" style="width:180px;letter-spacing: 0.5px;" class="btn-sm theme-button1 p-2">
                    View Sample Report
                </button>
            </div>
        </div> -->
        <div class="col-md-12">
            <h2 class="w-100 secondaryText font-xxl font-weight-normal unlock-text">Unlock your</h2>
            <h2 class="w-100 primaryText font-weight-bold font-xxl">Full Potential</h2>
            <h3 class="w-100 lightText m-Description">
                Upgrade your plan and complete all the assessments to view your comprehensive report and roadmap. </h3>
            <div class="row pl-2 progress-row">
                <div class="col-md-11 progressBox">
                    <div class="row boxSpace w-100">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-12 pl-3 pr-4 ml-2">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="progress-heading mr-3 text-nowrap letter-spacing">
                                                {{'Overall Progress'}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="custom-bar d-flex pr-0 pt-1 pb-2" translate="no">
                                                <span *ngIf="avgProgressForProfile != 100" class="avgPercent"
                                                    style="margin-top:-5px"
                                                    [ngStyle]="{ 'left': avgProgressForProfile*0.92 +  '%' }">{{avgProgressForProfile}}<sub>%</sub></span>
                                                <img class="blue-tick-img" *ngIf="avgProgressForProfile == 100"
                                                    style="position: absolute;height:24px;z-index:1;margin-top:-5px"
                                                    [ngStyle]="{ 'left': avgProgressForProfile*0.92 +  '%' }"
                                                    src="assets/svg/assessments/Blue_Tick.svg" />

                                                <mat-progress-bar class="custom-ProgressBar mt-1" mode="determinate"
                                                    [value]="avgProgressForProfile">
                                                    <!-- <mat-icon svgIcon="progress-arrow" class="progress-icon"></mat-icon> -->
                                                </mat-progress-bar>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 tealText">
                                            {{'Mandatory Profile'}}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
            <div class="row mx-auto mb-3 mt-4" *ngIf="avgProgressForProfile != '100' ">
                <button style="width:180px;letter-spacing: 0.5px;" class="btn-sm theme-button1 p-2 m-btn"
                    (click)="goToProfile()">
                    Go to Profile
                </button>
            </div>
            <div class="row pl-2 progress-row">
                <div class="col-md-11 progressBox">
                    <div class="row boxSpace w-100">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-12 pl-3 pr-4 ml-2">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="progress-heading mr-3 text-nowrap letter-spacing">
                                                {{'Overall Progress'}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="custom-bar d-flex pr-0 pt-1 pb-2" translate="no">
                                                <span *ngIf="avgProgress != 100" class="avgPercent"
                                                    style="margin-top:-5px"
                                                    [ngStyle]="{ 'left': avgProgress*0.92 +  '%' }">{{avgProgress}}<sub>%</sub></span>
                                                <img class="blue-tick-img" *ngIf="avgProgress == 100"
                                                    style="position: absolute;height:24px;z-index:1;margin-left:14px;margin-top:-5px"
                                                    [ngStyle]="{ 'left': avgProgress*0.92 +  '%' }"
                                                    src="assets/svg/assessments/Blue_Tick.svg" />

                                                <mat-progress-bar class="custom-ProgressBar mt-1" mode="determinate"
                                                    [value]="avgProgress">
                                                    <!-- <mat-icon svgIcon="progress-arrow" class="progress-icon"></mat-icon> -->
                                                </mat-progress-bar>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 tealText">
                                            {{'Psychometric Assessment'}}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
            <div class="row mx-auto mb-3 mt-4">
                <button style="width:180px;letter-spacing: 0.5px;" class="btn-sm theme-button1 p-2 m-btn"
                    (click)="goToPricing()">Upgrade</button>
            </div>
        </div>
    </div>
</div>

<div class="container" *ngIf="screen != '' && screen == 'second'">
    <div class="row mt-2 m-sticky">
        <div class="col-md-12">
            <h3 class="lightText font-weight-bold m-heading">Report</h3>
        </div>
    </div>
    <div class="row">
        <!-- <div class="col-md-5 position-relative img-container">
            <img class="w-100 mb-3" src="../../../../assets/svg/FullReport/report-dark.svg"/>
            <div class="vertical-center text-center w-100">
                <button (click)="openSampleReport()" style="width:180px;letter-spacing: 0.5px;" class="btn-sm theme-button1 p-2">
                    View Sample Report
                </button>
            </div>
        </div> -->
        <div class="col-md-12">
            <h3 class="secondaryText font-weight-bold secondary-title m-title" style="width:68%;">
                Complete all the assessments and profile to view your comprehensive report and roadmap </h3>
            <h3 class="w-100 lightText m-Description">
                The ProTeen report is designed to be a thorough and comprehensive solution. After conducting a rigorous
                assessment on various psychometric parameters and subsequent scientific analysis of individual
                responses, the ProTeen Report provides you with a detailed description of your interest areas,
                personality, aptitude, multiple intelligences, and best academic & career fit. </h3>
            <div class="row pl-2 progress-row">
                <div class="col-md-11 progressBox">
                    <div class="row boxSpace w-100">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-12 pl-3 pr-4 ml-2">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="progress-heading mr-3 text-nowrap letter-spacing">
                                                {{'Overall Progress'}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="custom-bar d-flex pr-0 pt-1 pb-2" translate="no">
                                                <span *ngIf="avgProgressForProfile != 100" class="avgPercent"
                                                    style="margin-top:-5px"
                                                    [ngStyle]="{ 'left': avgProgressForProfile*0.92 +  '%' }">{{avgProgressForProfile}}<sub>%</sub></span>
                                                <img *ngIf="avgProgressForProfile == 100"
                                                    style="position: absolute;height:24px;z-index:1;margin-left:14px;margin-top:-5px"
                                                    [ngStyle]="{ 'left': avgProgressForProfile*0.92 +  '%' }"
                                                    src="assets/svg/assessments/Blue_Tick.svg" />

                                                <mat-progress-bar class="custom-ProgressBar mt-1" mode="determinate"
                                                    [value]="avgProgressForProfile">
                                                    <!-- <mat-icon svgIcon="progress-arrow" class="progress-icon"></mat-icon> -->
                                                </mat-progress-bar>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 tealText">
                                            {{'Mandatory Profile'}}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
            <div class="row mx-auto mb-3 mt-4" *ngIf="avgProgressForProfile != '100' ">
                <button style="width:180px;letter-spacing: 0.5px;" class="btn-sm theme-button1 p-2 m-btn"
                    (click)="goToProfile()">
                    Go to Profile
                </button>
            </div>
            <div class="row pl-2 progress-row">
                <div class="col-md-11 progressBox">
                    <div class="row boxSpace w-100">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-12 pl-3 pr-4 ml-2">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="progress-heading mr-3 text-nowrap letter-spacing">
                                                {{'Overall Progress'}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="custom-bar d-flex pr-0 pt-1 pb-2" translate="no">
                                                <span *ngIf="avgProgress != 100" class="avgPercent"
                                                    style="margin-top:-5px"
                                                    [ngStyle]="{ 'left': avgProgress*0.92 +  '%' }">{{avgProgress}}<sub>%</sub></span>
                                                <img *ngIf="avgProgress == 100"
                                                    style="position: absolute;height:24px;z-index:1;margin-left:14px;margin-top:-5px"
                                                    [ngStyle]="{ 'left': avgProgress*0.92 +  '%' }"
                                                    src="assets/svg/assessments/Blue_Tick.svg" />

                                                <mat-progress-bar class="custom-ProgressBar mt-1" mode="determinate"
                                                    [value]="avgProgress">
                                                    <!-- <mat-icon svgIcon="progress-arrow" class="progress-icon"></mat-icon> -->
                                                </mat-progress-bar>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 tealText">
                                            {{'Psychometric Assessment'}}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
            <div class="row mx-auto mb-3 mt-4">
                <button style="width:180px;letter-spacing: 0.5px;" class="btn-sm theme-button1 p-2 m-btn"
                    (click)="goToAssessment()">
                    Go to Assessments
                </button>
            </div>
        </div>
    </div>
</div>

<div class="container" *ngIf="screen != '' && screen == 'third'">
    <div class="row mt-2 m-sticky">
        <div class="col-md-12">
            <h3 *ngIf="textShow" class="lightText font-weight-bold m-heading">My Report</h3>
        </div>
    </div>
    <div class="row mobile-column">
        <!-- <div class="col-md-5 position-relative img-container">
            <img class="w-100 mb-3 report-img" src="../../../../assets/svg/FullReport/report-dark.svg"/>
            <div class="vertical-center text-center w-100" *ngIf="downloadButton">
                <button  (click)="openSampleReport()" style="width:180px;letter-spacing: 0.5px;" class="btn-sm theme-button1 p-2">
                    View Sample Report
                </button>
            </div>
            <div class="vertical-center text-center w-100" *ngIf="!downloadButton">
                <button  [disabled]="fetchingReport" (click)="initDownload(true)" style="width:180px;letter-spacing: 0.5px;" class="btn-sm theme-button1 p-2">
                    View Report
                </button>
            </div>
            <div class="vertical-center-print text-center" *ngIf="!downloadButton">
                <button *ngIf="isLargeDevice"  [disabled]="fetchingReport" (click)="onPrint(true)" style="width: 53px;height: 24px;letter-spacing: 0.5px;" class="btn-sm theme-button1 p-2">
                  <p style="margin-top: -7px;">Print</p>  
                </button>
            </div>
        </div> -->
        <div class="col-md-12">
            <h3 *ngIf="avgProgressForProfile =='100'"
                class="secondaryText font-weight-bold secondary-title m-Description" style="width:68%;">
                View and download your comprehensive report and roadmap now! </h3>
            <h3 *ngIf="avgProgressForProfile !='100'" class="secondaryText font-weight-bold m-title secondary-title"
                style="width:68%;">
                Complete profile to view your comprehensive report and roadmap </h3>
            <h3 *ngIf="avgProgressForProfile =='100'" class="w-100 lightText  m-Description">
                The ProTeen report is designed to be a thorough and comprehensive solution. After conducting a rigorous
                assessment on various psychometric parameters and subsequent scientific analysis of individual
                responses, the ProTeen Report provides you with a detailed description of your interest areas,
                personality, aptitude, multiple intelligences, and best academic & career fit.</h3>
            <div class="row pl-2 progress-row">
                <div class="col-md-11 progressBox">
                    <div class="row boxSpace w-100">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-12 pl-3 pr-4 ml-2">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="progress-heading mr-3 text-nowrap letter-spacing">
                                                {{'Overall Progress'}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="custom-bar d-flex pr-0 pt-1 pb-2" translate="no">
                                                <span *ngIf="avgProgressForProfile != 100" class="avgPercent"
                                                    style="margin-top:-5px"
                                                    [ngStyle]="{ 'left': avgProgressForProfile*0.92 +  '%' }">{{avgProgressForProfile}}<sub>%</sub></span>
                                                <img *ngIf="avgProgressForProfile == 100"
                                                    style="position: absolute;height:24px;z-index:1;margin-left:14px;margin-top:-5px"
                                                    [ngStyle]="{ 'left': avgProgressForProfile*0.92 +  '%' }"
                                                    src="assets/svg/assessments/Blue_Tick.svg" />

                                                <mat-progress-bar class="custom-ProgressBar mt-1" mode="determinate"
                                                    [value]="avgProgressForProfile">
                                                    <!-- <mat-icon svgIcon="progress-arrow" class="progress-icon"></mat-icon> -->
                                                </mat-progress-bar>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 tealText">
                                            {{'Mandatory Profile'}}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
            <div class="row mx-auto mb-3 mt-4" *ngIf="avgProgressForProfile != '100' ">
                <button style="width:180px;letter-spacing: 0.5px;" class="btn-sm theme-button1 p-2 m-btn"
                    (click)="goToProfile()">
                    Go to Profile
                </button>
            </div>
            <div class="row pl-2">
                <div class="col-md-11 progressBox">
                    <div class="row boxSpace w-100">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-12 pl-3 pr-4 ml-2">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="progress-heading mr-3 text-nowrap letter-spacing">
                                                {{'Overall Progress'}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="custom-bar d-flex pr-0 pt-1 pb-2" translate="no">
                                                <span *ngIf="avgProgress != 100" class="avgPercent"
                                                    style="margin-top:-5px"
                                                    [ngStyle]="{ 'left': avgProgress*0.92 +  '%' }">{{avgProgress}}<sub>%</sub></span>
                                                <img *ngIf="avgProgress == 100"
                                                    style="position: absolute;height:24px;z-index:1;margin-left:14px;margin-top:-5px"
                                                    [ngStyle]="{ 'left': avgProgress*0.92 +  '%' }"
                                                    src="assets/svg/assessments/Blue_Tick.svg" />

                                                <mat-progress-bar class="custom-ProgressBar mt-1" mode="determinate"
                                                    [value]="avgProgress">
                                                    <!-- <mat-icon svgIcon="progress-arrow" class="progress-icon"></mat-icon> -->
                                                </mat-progress-bar>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 tealText">
                                            {{'Psychometric Assessment'}}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
            <!--div class="row mx-auto mb-3 mt-4" *ngIf="false">
                <ng-select appDropdown [placeholder]="'Language'"
                class="single-select lang-select"
                [(ngModel)]="langCode"
                appearance="outline"
                [clearOnBackspace]="true"
                dropdownPosition="bottom"
                loadingText="Loading..."
                bindLabel="title">
                    <ng-option *ngFor="let lang of langList"
                    [value]="lang.code">{{lang.title}}</ng-option>
                </ng-select>
            </div-->
            <div class="row mx-auto mb-3 mt-4" *ngIf="!fetchingReport">
                <!-- <button [disabled]="downloadButton" (click)="initDownload(false)" style="width:180px;letter-spacing: 0.5px;" class="btn-sm theme-button1 p-2 m-btn" >
                    Download Report
                </button> -->
                <!-- added summary button 07122022 -->
                <div class="col-6"><button [disabled]="downloadButton" (click)="initDownload(false)"
                        style="width:180px;letter-spacing: 0.5px;" class="btn-sm theme-button1 p-2 m-btn">
                        Download Detailed Report
                    </button></div>
                <div class="col-6"><button [disabled]="downloadButton" (click)="initDownloadShortReport(false)"
                        style="width:180px;letter-spacing: 0.5px; color: #fff; background-color: #22A7B6; border: 1px solid #22A7B6 !important;;"
                        class="btn-sm theme-button2 p-2 m-btn">
                        Download Summary Report
                    </button></div>
            </div>
            <div class="row mx-auto mb-3 mt-4" *ngIf="fetchingReport">
                <div class="col-md-12 d-flex flex-row">
                    <span class="mr-2" style="font-size:27px;color:#FF6127"><i class="fa fa-refresh fa-spin"></i></span>
                    <h3>Please wait for a few seconds while your report is being generated...</h3>
                </div>

            </div>
        </div>
    </div>
</div>