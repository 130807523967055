import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root'
})

export class UserService {
  private dataSource = new BehaviorSubject<number>(0);
  data = this.dataSource.asObservable();


  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': '',
    })
  };

  entity_id: any;
  private apiUrl: string;
  private apiUrlAdmin: string;
  private apiUrlCareer: string;
  private apiUrlStudent: string;
  constructor(private _snackBar: MatSnackBar, private http: HttpClient, private _authService: AuthService) {
    const BASE_API_URL = environment.ApiConfig.settings;
    const entity_id = environment.entity_id;
    this.apiUrl = `${BASE_API_URL}/settings`;
    const BASE_API_URL_admin = environment.ApiConfig.admin;
    this.apiUrlStudent = environment.ApiConfig.student;
    this.apiUrlAdmin = `${BASE_API_URL_admin}/admin`;
    this.apiUrlCareer = environment.ApiConfig.careerinfo
    this._authService.getUserDetails().then(data => {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': data.user.signInUserSession.idToken.jwtToken,
        })

      };
      //this.http.options(this.httpOptions);


    })
  }


  //user Details  APIS
  getuserDetails(uuid) {
    return this.http.get(`${this.apiUrl}/dashboard/users/student/profile?uuid=${uuid}`, this.httpOptions);
  }

  getAssessmentCount(uuid) {
    return this.http.get(`${this.apiUrl}/dashboard/users/student/assessment?uuid=${uuid}`, this.httpOptions);
  }
  getDashboardCount(entity_id) {
    return this.http.get(`${this.apiUrlAdmin}/dashboard/count?entity_id=${entity_id}`, this.httpOptions);
  }
  getDashboardFeedback(entity_id) {
    return this.http.get(`${this.apiUrlAdmin}/dashboard/feedback?entity_id=${entity_id}`, this.httpOptions);
  }
  getDashboardPlans(entity_id) {
    return this.http.get(`${this.apiUrlAdmin}/dashboard/plans?entity_id=${entity_id}`, this.httpOptions);
  }
  getDashboardReport(entity_id) {
    return this.http.get(`${this.apiUrlAdmin}/dashboard/report?entity_id=${entity_id}`, this.httpOptions);
  }
  getDashboardAssessment(entity_id) {
    return this.http.get(`${this.apiUrlAdmin}/dashboard/assessment?entity_id=${entity_id}`, this.httpOptions);
  }
  getProfileCount(uuid) {
    return this.http.get(`${this.apiUrlAdmin}/dashboard/profile?uuid=${uuid}&type=single`, this.httpOptions);
  }
  getMandatoryProfileCount(uuid) {
    let entity = 25;
    return this.http.get(`${this.apiUrlAdmin}/dashboard/profile?uuid=${uuid}&type=profile_mandatory&entity_id=${entity}`, this.httpOptions);
  }
  getProfileTotolCount(entity_id) {
    return this.http.get(`${this.apiUrlAdmin}/dashboard/profile?type=all&entity_id=${entity_id}`, this.httpOptions);
  }
  getDashboardLineChart(data, entity_id) {
    console.log('selectVal-----------', data.selected)
    if (data.selected == 'Days') {
      if (data.val == 2) {
        return this.http.get(`${this.apiUrlAdmin}/dashboard/graph?dailyFrom=${data.fromdate}&dailyTo=${data.todate}&entity_id=${entity_id}`, this.httpOptions);
      } else {
        return this.http.get(`${this.apiUrlAdmin}/dashboard/graph?type=days&month=1&entity_id=${entity_id}`, this.httpOptions);
      }
    } else if (data.selected == 'Months') {
      if (data.val == 2) {
        return this.http.get(`${this.apiUrlAdmin}/dashboard/graph?monthFrom=${data.fromdate}&monthTo=${data.todate}&entity_id=${entity_id}`, this.httpOptions);
      } else {
        return this.http.get(`${this.apiUrlAdmin}/dashboard/graph?type=month&entity_id=${entity_id}`, this.httpOptions);
      }
    } else if (data.selected == 'Year') {
      if (data.val == 2) {
        return this.http.get(`${this.apiUrlAdmin}/dashboard/graph?yearFrom=${data.fromdate}&yearTo=${data.todate}&entity_id=${entity_id}`, this.httpOptions);
      } else {
        return this.http.get(`${this.apiUrlAdmin}/dashboard/graph?type=year&entity_id=${entity_id}`, this.httpOptions);
      }
    }

  }

  getDashboardCareerGraphChart(data, entity_id) {
    console.log('selectVal-----------', data.selected)
    if (data.selected == 'Days') {
      if (data.val == 2) {
        return this.http.get(`${this.apiUrlAdmin}/dashboard/graph-careers-clusters?dailyFrom=${data.fromdate}&dailyTo=${data.todate}&entity_id=${entity_id}`, this.httpOptions);
      } else {
        return this.http.get(`${this.apiUrlAdmin}/dashboard/graph-careers-clusters?type=days&month=1&entity_id=${entity_id}`, this.httpOptions);
      }
    } else if (data.selected == 'Months') {
      if (data.val == 2) {
        return this.http.get(`${this.apiUrlAdmin}/dashboard/graph-careers-clusters?monthFrom=${data.fromdate}&monthTo=${data.todate}&entity_id=${entity_id}`, this.httpOptions);
      } else {
        return this.http.get(`${this.apiUrlAdmin}/dashboard/graph-careers-clusters?type=month&entity_id=${entity_id}`, this.httpOptions);
      }
    } else if (data.selected == 'Year') {
      if (data.val == 2) {
        return this.http.get(`${this.apiUrlAdmin}/dashboard/graph-careers-clusters?yearFrom=${data.fromdate}&yearTo=${data.todate}&entity_id=${entity_id}`, this.httpOptions);
      } else {
        return this.http.get(`${this.apiUrlAdmin}/dashboard/graph-careers-clusters?type=year&entity_id=${entity_id}`, this.httpOptions);
      }
    }

  }
  getDashboardQuizGraphChart(data) {
    console.log('selectVal-----------', data.selected)
    if (data.selected == 'Days') {
      if (data.val == 2) {
        return this.http.get(`${this.apiUrlAdmin}/dashboard/graph/career-quiz?dailyFrom=${data.fromdate}&dailyTo=${data.todate}&entity_id=${environment.entity_id}`, this.httpOptions);
      } else {
        return this.http.get(`${this.apiUrlAdmin}/dashboard/graph/career-quiz?type=days&month=1&entity_id=${environment.entity_id}`, this.httpOptions);
      }
    } else if (data.selected == 'Months') {
      if (data.val == 2) {
        return this.http.get(`${this.apiUrlAdmin}/dashboard/graph/career-quiz?monthFrom=${data.fromdate}&monthTo=${data.todate}&entity_id=${environment.entity_id}`, this.httpOptions);
      } else {
        return this.http.get(`${this.apiUrlAdmin}/dashboard/graph/career-quiz?type=month&entity_id=${environment.entity_id}`, this.httpOptions);
      }
    } else if (data.selected == 'Year') {
      if (data.val == 2) {
        return this.http.get(`${this.apiUrlAdmin}/dashboard/graph/career-quiz?yearFrom=${data.fromdate}&yearTo=${data.todate}&entity_id=${environment.entity_id}`, this.httpOptions);
      } else {
        return this.http.get(`${this.apiUrlAdmin}/dashboard/graph/career-quiz?type=year&entity_id=${environment.entity_id}`, this.httpOptions);
      }
    }

  }
  getStudentDetails(section, uuid) {

    if (section == 'basic' || section == 'education' || section == 'family' || section == 'personal') {
      let finalURL = `${this.apiUrlStudent}/student/profile/${section}/${uuid}`
      if (section == 'basic' || section == 'education' || section == 'family' || section == 'personal') {
        finalURL += '?entity_id=' + environment.entity_id
      }
      return this.http.get(finalURL, this.httpOptions);
    }
  }

  updateStudentDetails(userAttributes, section, uuid) {
    //userAttributes.updated_at =this.getCurrentDate();;
    if (section == 'basic' || section == 'education' || section == 'family' || section == 'personal') {
      if (section == 'basic') {
        userAttributes.entity_id = environment.entity_id
      }
      return this.http.patch(`${this.apiUrlStudent}/student/profile/${section}/${uuid}`, userAttributes, this.httpOptions);
    }
  }

  addStudentDetails(userAttributes, section, uuid) {
    userAttributes.created_at = this.getCurrentDate();;
    userAttributes.updated_at = this.getCurrentDate();;

    if (section == 'basic' || section == 'education' || section == 'family' || section == 'personal') {
      // userAttributes.uuid = this.userSub;
      if (section == 'basic') {
        userAttributes.entity_id = environment.entity_id
      }
      if (section == 'personal') {
        return this.http.post(`${this.apiUrlStudent}/student/profile/${section}/${uuid}`, userAttributes, this.httpOptions);
      }
      else {
        userAttributes.uuid = uuid;
        return this.http.post(`${this.apiUrlStudent}/student/profile/${section}`, userAttributes, this.httpOptions);
      }

    }
  }

  getCurrentDate() {
    return moment().format("YYYY-MM-DD HH:mm:ss");

  }

  //Career APIS made dynamic for admin
  getCareerFit(type, uuid) {
    return this.http.get(`${this.apiUrlCareer}/student/career/fit?sub=${uuid}&type=${type}`, this.httpOptions);
  }

  getRecommendedCareers(uuid) {
    return this.http.get(`${this.apiUrlCareer}/student/career/recommendations?sub=${uuid}`, this.httpOptions);
  }

  //active user graph data
  getActiveUserData(oQueryData): Observable<any> {
    let url = this.apiUrlStudent + '/student/activitylog';
    url = url + '?entity_id=' + oQueryData.entityId + '&type=' + oQueryData.type;
    if (oQueryData.fromDate && oQueryData.toDate) {
      url = url + '&fromdate=' + oQueryData.fromDate + '&todate=' + oQueryData.toDate;
    }
    return this.http.get(url, this.httpOptions);
  }

}
